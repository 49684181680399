<template>
  <auth-wrapper>
    <v-snackbar v-model="error" color="error">
      {{ errorMessage }}
    </v-snackbar>
    <v-overlay v-if="$apollo.queries.userByResetToken.loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card-title class="justify-center" v-else>
      <div>
        <h1 class="text-center mb-2 display-4 font-weight-thin">hello</h1>
        <p class="body-2 text-center grey--text small">
          Use the below form to set your password
        </p>
      </div>
    </v-card-title>
    <v-container>
      <v-form v-model="valid">
        <v-text-field
          label="Email"
          type="email"
          solo
          readonly
          disabled
          :value="userByResetToken.username"
        ></v-text-field>
        <v-text-field
          label="Password"
          type="password"
          solo
          v-model="form.password"
          :rules="[rules.required, rules.minSix]"
        ></v-text-field>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-btn
        class="nice-button primary"
        x-large
        rounded
        block
        :disabled="!valid"
        :loading="loading"
        @click="setPassword"
        >Set password</v-btn
      >
    </v-card-actions>
  </auth-wrapper>
</template>

<script>
import Cookie from "js-cookie";
import AuthWrapper from "@/components/AuthWrapper";
import { queries, mutations } from "@/gql";
import { BACKEND_DOMAIN } from "@/config";

const { userByResetToken: query } = queries;
const { setPassword: mutation } = mutations;

export default {
  components: { AuthWrapper },
  data: () => ({
    error: false,
    errorMessage: null,
    valid: null,
    loading: false,
    form: { password: null },
    rules: {
      required: (v) => !!v || "This field is required",
      minSix: (v) => (!!v && v.length > 5) || "Please enter a longer password",
    },
  }),
  apollo: {
    userByResetToken: {
      query,
      variables() {
        return {
          token: this.$route.params.token,
        };
      },
    },
  },
  methods: {
    setPassword() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            token: this.$route.params.token,
            password: this.form.password,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then(
          ({
            data: {
              setPassword: { jwtToken },
            },
          }) => {
            Cookie.set("JWT", jwtToken, { domain: BACKEND_DOMAIN });
            this.$router.push("/");
            this.loading = false;
          },
        );
    },
  },
};
</script>

<style scoped>
.nice-button {
  text-transform: none !important;
  letter-spacing: 0;
}
</style>
