import { render, staticRenderFns } from "./SetPassword.vue?vue&type=template&id=31b3e7ef&scoped=true&"
import script from "./SetPassword.vue?vue&type=script&lang=js&"
export * from "./SetPassword.vue?vue&type=script&lang=js&"
import style0 from "./SetPassword.vue?vue&type=style&index=0&id=31b3e7ef&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b3e7ef",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCardActions,VCardTitle,VContainer,VForm,VOverlay,VProgressCircular,VSnackbar,VTextField})
